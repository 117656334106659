<template>
  <div v-if="me" class="d-print-none">
    <v-sheet class="header-backup" />
    <v-app-bar
      color="transparent"
      app
      class="px-sm text-left ma-4 rounded-lg"
      flat
      height="75"
    >
      <v-app-bar-nav-icon
        v-ripple="{ class: 'primary--text text--lighten-2' }"
        @click="toggleVerticalSidebarDrawer"
      />

      <h3 class="title font-weight-regular my-0 py-0 ml-2">
        {{ currentRouteName }}
      </h3>
      <v-progress-linear
        :active="getThemeMode.isLoading"
        :indeterminate="getThemeMode.isLoading"
        absolute
        bottom
        color="primary"
      />

      <v-spacer />

      <span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :color="$vuetify.theme.dark ? '' : 'secondary'"
              v-bind="attrs"
              v-on="on"
              @click="changeTheme"
            >
              <v-icon large :class="$vuetify.theme.dark ? '' : 'LightModeBtn'">mdi-theme-light-dark</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.theme.dark ? 'Light Mode' : 'Dark Mode' }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              color="lightPrimary darken-1"
              class="ml-4 rounded-lg cursor-pointer"
              :class="isOver ? 'lightPrimary darken-2' : 'lightPrimary darken-1'"
              @mouseover="isOver = true"
              @mouseleave="isOver = false"
              style="font-size: 16px;"
              rounded
              size="46"
              v-bind="attrs"
              v-on="on"
              @click.stop="changeUserDrawer"
            >
              <span class="primary--text text--lighten-2 font-weight-bold">{{ me.fullName.split(" ").map((n)=>n[0]).join("") }}</span>
            </v-avatar>
          </template>
          <span>Profile</span>
        </v-tooltip>
      </span>
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer
      v-if="userDrawer"
      v-click-outside="changeUserDrawer"
      :value="userDrawer"
      fixed
      class="rounded-l-lg"
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <user-drawer :me="me">
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="changeUserDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <v-list-item-content>
            <v-btn class="mb-2" outlined @click="resetPassword">
              Change password
            </v-btn>
            <v-btn
              class="mb-2"
              outlined
              elevation="0"
              @click="showEditDialog = true"
            >
              Edit profile
            </v-btn>
            <v-btn color="primary" elevation="0" @click="logoutUser">
              Sign out
            </v-btn>
          </v-list-item-content>
        </div>
      </template>
    </v-navigation-drawer>
    <edit-profile-dialog :data="me" :show-edit-dialog.sync="showEditDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EditProfileDialog from "@/components/dialog/profile/EditProfileDialog.vue"
import snackbarPlugin from "@/plugins/snackbar"
import AuthService from "@/services/auth.service"
import UserService from '@/services/user.service'

export default {
  name: 'VerticallAppBar',
  components: {
    UserDrawer: () => import('./common-drawer/UserDrawer.vue'),
    EditProfileDialog
  },
  props: ['me'],
  data() {
    return {
      isClientAdmin: false,
      showEditDialog: false,
      showEditPentest: false,
      isOver: false,
      dialog: false,
      showCreateAsset: false,
      searchDrawer: false,
      navbarOptions: {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: "./",
        brandImageAltText: "brand-image",
        collapseButtonOpenColor: "#661c23",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away"
      }
    }
  },
  computed: {
    ...mapGetters(["getThemeMode", "getUserDrawer"]),
    currentRouteName() {
      return this.$route.name
    },
    userDrawer() {
      return this.getUserDrawer
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    this.isClientAdmin = profile.role === 'Client Admin' || profile.role === 'Superuser'

    if (localStorage.theme === "true") {
      this.$vuetify.theme.dark = true
    }
    if (localStorage.theme === "false") {
      this.$vuetify.theme.dark = false
    }
  },
  methods: {
    ...mapActions([
      "changeVerticalSidebarDrawer",
      "changeVerticalSidebarMini",
      "changeUserDrawer",
      "signOut"
    ]),
    async resetPassword() {
      snackbarPlugin.showLoading('Sending ...')
      await AuthService.passwordReset({ email: this.me.email }).then(
        (resp) => {
          if (resp.status === 200) {
            snackbarPlugin.showSuccess(`An email has been sent to you at ${this.me.email}`)
          }
        }
      )
    },
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer()
      // this.$emit("update:mini-variant")
    },
    logoutUser() {
      snackbarPlugin.showLoading('Disconnecting ...')
      this.changeUserDrawer()
      this.$store.dispatch("auth/logout", this.user).then(() => {
        this.$router.push("/login")
        setTimeout(() => {
          snackbarPlugin.showSuccess('You are successfully logged out !')
        }, 1000)
      })
    },
    changeTheme() {
      if (this.$vuetify.theme.dark) {
        localStorage.theme = false
        this.$vuetify.theme.dark = false
      } else {
        localStorage.theme = true
        this.$vuetify.theme.dark = true
      }
    }
  }
}
</script>

<style lang="scss">
.LightModeBtn {
  transform: rotate(180deg);
}
.header-backup {
  display: block;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;
  &.theme--dark {
    background: linear-gradient(
      180deg,
      rgba(5, 5, 5, 0.95) 44%,
      rgba(0, 0, 0, 0.46) 3%,
      hsla(0, 0%, 100%, 0)
    ) !important;
  }
}
.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "/F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}
</style>
